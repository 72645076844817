import React, { Fragment } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { Button, Dialog } from '@material-ui/core';
import { request } from '../../../util/request';
import { getAbTestDailyData } from '../../../actions/homeAppActions';
import ABContentClick from './ABContentClick';
import ABUniqueDevice from './ABUniqueDevice';
import ABContentDuration from './ABContentDuration';
import EditReasonValidityDialog from './EditReasonValidityDialog';
import CircleIcon from '@material-ui/icons/FiberManualRecord';
import Tooltip from '@material-ui/core/Tooltip';
import { makeStyles } from '@material-ui/core/styles';
import Help from '@material-ui/icons/Help';

function ABTestResult({ history, id, segMentId, getAbTestDailyData, abTestDailyData }) {
  const [abTestTitle, setAbTestTitle] = React.useState('');
  const [country, setCountry] = React.useState('');
  const [segmentId, setSegmentId] = React.useState('');
  const [categoryCode, setCategoryCode] = React.useState('');
  const [categoryName, setCategoryName] = React.useState('');
  const [abtestStartTime, setAbtestStartTime] = React.useState('');
  const [abtestEndTime, setAbtestEndTime] = React.useState('');
  const [tabCode, setTabCode] = React.useState('');
  const [tabName, setTabName] = React.useState('');
  const [segmentType, setSegmentType] = React.useState('');
  const [segmentName, setSegmentName] = React.useState('');
  const [description, setDescription] = React.useState('');
  const [defaultContent, setDefaultContent] = React.useState('');
  const [contentPolicy, setContentPolicy] = React.useState('');
  const [openEditResult, setOpenEditResult] = React.useState(false);
  const [validity, setValidity] = React.useState();
  const [reason, setReason] = React.useState('');
  const [validityData, setValidityData] = React.useState();
  const [reasonData, setReasonData] = React.useState('');
  const [mappingType, setMappingType] = React.useState('');
  const [categoryMappingType, setCategoryMappingType] = React.useState('');
  const [cSegmentType, setCSegmentType] = React.useState('');
  const [cSegmentId, setCSegmentId] = React.useState('');
  const [uniqueDeviceA, setUniqueDeviceA] = React.useState('');
  const [uniqueDeviceB, setUniqueDeviceB] = React.useState('');
  const [uniqueDeviceC, setUniqueDeviceC] = React.useState('');
  const [lastDate, setLastDate] = React.useState('');
  const [startDate, setStartDate] = React.useState('');
  const [durationCumulativeBoverA, setDurationCumulativeBoverA] = React.useState('');
  const [durationCumulativeBoverC, setDurationCumulativeBoverC] = React.useState('');
  const [clickCumulativeBoverA, setClickCumulativeBoverA] = React.useState('');
  const [clickCumulativeBoverC, setClickCumulativeBoverC] = React.useState('');
  const [udCumulativeBoverA, setUdCumulativeBoverA] = React.useState('');
  const [udCumulativeBoverC, setUdCumulativeBoverC] = React.useState('');
  const [dailyUdsData, setDailyUdsData] = React.useState(null);
  const [udsDescriptionA, setUdsDescriptionA] = React.useState('');
  const [udsDescriptionB, setUdsDescriptionB] = React.useState('');
  const [udsDescriptionC, setUdsDescriptionC] = React.useState('');

  React.useEffect(() => {
    fetchTbTestData();
  }, [id]);

  React.useEffect(() => {
    const dailyData = abTestDailyData.filter(data => data.abtest_id != null);

    if (dailyData.length > 0) {
      // set startDate
      setStartDate(dailyData[0].start_date.slice(0, 19).split('T')[0]);

      // set value by last Data
      const lastData = dailyData[dailyData.length - 1];
      setUniqueDeviceA(formatNum(lastData.agg_homeapp_unique_device_A));
      setUniqueDeviceB(formatNum(lastData.agg_homeapp_unique_device_B));
      setUniqueDeviceC(formatNum(lastData.agg_homeapp_unique_device_C));
      setLastDate(lastData.start_date.slice(0, 19).split('T')[0]);

      // Average Duration Cumulative B over A, B over C
      setDurationCumulativeBoverA(calImprovedRate(lastData.agg_all_content_B, lastData.agg_all_content_A));
      setDurationCumulativeBoverC(calImprovedRate(lastData.agg_all_content_B, lastData.agg_all_content_C));

      // Content Click Rate Cumulatvie B over A, B over C
      setClickCumulativeBoverA(calImprovedRate(lastData.agg_content_B, lastData.agg_content_A));
      setClickCumulativeBoverC(calImprovedRate(lastData.agg_content_B, lastData.agg_content_C));

      // Clicked Unique Device Rate Cumulative B over A, B over C
      setUdCumulativeBoverA(calImprovedRate(lastData.agg_uniquedevice_B, lastData.agg_uniquedevice_A));
      setUdCumulativeBoverC(calImprovedRate(lastData.agg_uniquedevice_B, lastData.agg_uniquedevice_C));
    } else {
      // initialize
      setStartDate('');
      setUniqueDeviceA('');
      setUniqueDeviceB('');
      setUniqueDeviceC('');
      setLastDate('');
      setDurationCumulativeBoverA('');
      setDurationCumulativeBoverC('');
      setClickCumulativeBoverA('');
      setClickCumulativeBoverC('');
      setUdCumulativeBoverA('');
      setUdCumulativeBoverC('');
    }
  }, [abTestDailyData]);

  const getDailyUdsData = async (bSegmentType, abTestId, sampleRate, segmentTarget, cGroupRate) => {
    const dailyUdsData = await request(`/api/abtest/daily-uds/${abTestId}/segment-type/${bSegmentType}`, 'GET', null, true);
    let dailyUds = null;
    if (bSegmentType === 'CMP') {
      const key = `total_unique_users_${segmentTarget}_adts`;
      dailyUds = dailyUdsData.reduce((acc, item) => {
        const bValue = item[key] ? Math.floor((((item[key] * sampleRate) / 100) * (100 - cGroupRate)) / 100) : null;
        const cValue = item[key] ? Math.floor((((item[key] * sampleRate) / 100) * cGroupRate) / 100) : null;
        acc[item.DT] = {
          A: bValue && cValue && item.total_unique_users_beacon ? item.total_unique_users_beacon - bValue - cValue : 'N/A',
          B: bValue ? bValue : 'N/A',
          C: cValue ? cValue : 'N/A',
        };
        return acc;
      }, {});
    } else if (bSegmentType === 'PER') {
      dailyUds = dailyUdsData.reduce((acc, item) => {
        const total = item.total_unique_users_beacon;
        const bValue = total ? Math.floor((((total * sampleRate) / 100) * (100 - cGroupRate)) / 100) : null;
        const cValue = total ? Math.floor((((total * sampleRate) / 100) * cGroupRate) / 100) : null;
        acc[item.DT] = {
          A: bValue && cValue && total ? total - bValue - cValue : 'N/A',
          B: bValue ? bValue : 'N/A',
          C: cValue ? cValue : 'N/A',
        };
        return acc;
      }, {});
    }
    setDailyUdsData(dailyUds);
  };

  const getUdsDescriptionB = tbTestData => {
    let p1 = '',
      p2 = '';
    const bGroupRate = Math.floor((Number(tbTestData.sampleRate) * (tbTestData.defaultContent === 'Y' ? 100 - tbTestData.csegmentSampleRate : 100)) / 100);

    if (tbTestData.segmentType === 'UPS' || tbTestData.segmentType === 'CMP') {
      if (tbTestData.mappingType === 'manual') {
        if (tbTestData.segmentTarget === 'ALL') {
          p1 = `This group has no segment but covers all the users in ${tbTestData.countryCode} -- randomly sampled with a ${bGroupRate} sample rate.`;
          p2 = ` From ${tbTestData.startTime} to ${tbTestData.endTime} (in local time), those users commonly watch the same list of contents that have been manually inserted from the segment organize page.`;
        } else {
          // tbTestData.segmentTarget !== 'ALL'
          p1 = `This group consists of users belonging to the ${tbTestData.segmentTarget} segment (${tbTestData.description}) -- randomly sampled in ${bGroupRate} sample rate.`;
          p2 = ` From ${tbTestData.startTime} to ${tbTestData.endTime} (in local time), those users commonly watch the same list of contents that have been manually inserted from the segment organize page.`;
        }
      } else if (tbTestData.mappingType === 'auto') {
        if (tbTestData.segmentTarget === 'ALL') {
          p1 = `This group has no segment but covers all the users in ${tbTestData.countryCode} -- randomly sampled with a ${bGroupRate} sample rate.`;
          p2 = ` From ${tbTestData.startTime} to ${tbTestData.endTime} (in local time), those users commonly  watches the same list of contents of ${tbTestData.bcontentPolicyAuto}.`;
        } else {
          // tbTestData.segmentTarget !== 'ALL'
          p1 = `This group consists of users belonging to the ${tbTestData.segmentTarget} segment (${tbTestData.description}) -- randomly sampled in ${bGroupRate} sample rate.`;
          p2 = ` From ${tbTestData.startTime} to ${tbTestData.endTime} (in local time), those users commonly  watches the same list of contents of ${tbTestData.bcontentPolicyAuto}. `;
        }
      }
    } else if (tbTestData.segmentType === 'PER') {
      if (tbTestData.mappingType === 'manual') {
        p1 = 'Unsupported segment type.';
      } else if (tbTestData.mappingType === 'auto') {
        if (tbTestData.segmentTarget === 'ALL') {
          p1 = `This group has no segment but covers all the users in ${tbTestData.countryCode} --  randomly sampled with a ${bGroupRate} sample rate.`;
          p2 = ` From ${tbTestData.startTime} to ${tbTestData.endTime} (in local time), each user watches his/her own personalized contents (${tbTestData.bcontentPolicyAuto}) in different orders.`;
        } else {
          // tbTestData.segmentTarget !== 'ALL'
          p1 = `This group consists of users belonging to the ${tbTestData.segmentTarget} segment (${tbTestData.description}) --  randomly sampled with a ${bGroupRate} sample rate.`;
          p2 = ` From ${tbTestData.startTime} to ${tbTestData.endTime} (in local time), each user watches his/her own personalized contents (${tbTestData.bcontentPolicyAuto}) in different orders.`;
        }
      }
    } else {
      p1 = 'Unsupported segment type.';
    }
    setUdsDescriptionB(
      <p>
        {p1}
        {p2}
      </p>,
    );
  };

  const getUdsDescriptionC = tbTestData => {
    let p1 = '',
      p2 = '';
    const computedCGroupRate = Math.floor((Number(tbTestData.sampleRate) * Number(tbTestData.csegmentSampleRate)) / 100);

    if (tbTestData.comparativeSegmentType === 'NONE') {
      if (tbTestData.cmappingType === 'manual') {
        p1 = 'Unsupported segment type.';
      } else if (tbTestData.cmappingType === 'auto') {
        if (tbTestData.segmentTarget === 'ALL') {
          p1 = `This group has no segment but covers all the users in ${tbTestData.countryCode} -- randomly sampled with a ${computedCGroupRate} sample rate.`;
          p2 = ` From ${tbTestData.startTime} to ${tbTestData.endTime} (in local time), those users commonly  watches the same list of contents of ${tbTestData.ccontentPolicyAuto}.`;
        } else {
          // selectedSegment.segmentTarget !== 'ALL'
          p1 = `This group consists of users belonging to the ${tbTestData.segmentTarget} segment (${tbTestData.description}) -- randomly sampled with a ${computedCGroupRate} sample rate.`;
          p2 = ` From ${tbTestData.startTime} to ${tbTestData.endTime} (in local time), those users commonly  watches the same list of contents of ${tbTestData.ccontentPolicyAuto}.`;
        }
      }
    } else if (tbTestData.comparativeSegmentType === 'PER') {
      if (tbTestData.cmappingType === 'manual') {
        p1 = 'Unsupported segment type.';
      } else if (tbTestData.cmappingType === 'auto') {
        if (tbTestData.segmentTarget === 'All') {
          p1 = `This group has no segment but covers all the users in ${tbTestData.countryCode} -- randomly sampled with a ${computedCGroupRate} sample rate.`;
          p2 = ` From ${tbTestData.startTime} to ${tbTestData.endTime} (in local time), each user watches his/her own personalized contents (${tbTestData.ccontentPolicyAuto}) in different orders.`;
        } else {
          // selectedSegment.segmentTarget !== 'ALL'
          p1 = `This group consists of users belonging to the ${tbTestData.segmentTarget} segment (${tbTestData.description}) -- randomly sampled with a ${computedCGroupRate} sample rate.`;
          p2 = ` From ${tbTestData.startTime} to ${tbTestData.endTime} (in local time), each user watches his/her own personalized contents (${tbTestData.ccontentPolicyAuto}) in different orders.`;
        }
      }
    } else {
      p1 = 'Unsupported segment type.';
    }

    setUdsDescriptionC(
      <p>
        {p1}
        {p2}
      </p>,
    );
  };

  const fetchTbTestData = async () => {
    const tbTestData = await request(`/api/abtest/${id}/segment/${segMentId}`, 'GET', null, true);
    setAbTestTitle(tbTestData.title);
    setCountry(tbTestData.countryCode);
    setSegmentId(tbTestData.segmentId);
    setCategoryCode(tbTestData.categoryCode);
    setCategoryName(tbTestData.categoryName);
    setAbtestStartTime(tbTestData.startTime);
    setAbtestEndTime(tbTestData.endTime);
    setTabCode(tbTestData.tabCode);
    setTabName(tbTestData.tabName);
    setSegmentType(tbTestData.segmentType);
    setSegmentName(tbTestData.segmentName);
    getAbTestDailyData(tbTestData.abtestId, tbTestData.abtestSubid);
    // daily uds data
    getDailyUdsData(tbTestData.segmentType, tbTestData.abtestId, tbTestData.sampleRate, tbTestData.segmentTarget, tbTestData.csegmentSampleRate);
    setDescription(tbTestData.description);
    setDefaultContent(tbTestData.defaultContent);
    setContentPolicy(tbTestData.contentPolicy);
    setValidity(tbTestData.validity);
    setReason(tbTestData.reason);
    setValidityData(tbTestData.validity);
    setReasonData(tbTestData.reason);
    setMappingType(tbTestData.mappingType);
    setCategoryMappingType(tbTestData.categoryMappingType);
    setCSegmentType(tbTestData.csegmentType);
    setCSegmentId(tbTestData.csegmentId);

    // Group A Description
    setUdsDescriptionA(
      <p>
        This group is the rest of users who had their own recommendation lists but assigned to neither the B group nor the C group. From {tbTestData.startTime} to {tbTestData.endTime} (in local time), those users commonly watches the same list of contents added by the content organization team from
        the category/tab page.
      </p>,
    );

    // Group B Description
    getUdsDescriptionB(tbTestData);

    // Group C Description
    getUdsDescriptionC(tbTestData);
  };

  function getCategoryDetails() {
    return categoryCode + ' ' + categoryName;
  }

  function calImprovedRate(content_one, content_two) {
    if (content_one == null || content_two == null || isNaN(content_one) || isNaN(content_two) || content_one <= 0 || content_two <= 0) {
      return 'N/A';
    }
    const improvedRate = ((content_one - content_two) * 100) / content_two === 0 ? 0 : (((content_one - content_two) * 100) / content_two).toFixed(1);
    if (isNaN(improvedRate)) {
      return '';
    }
    return improvedRate + ' %';
  }

  function contentClickRate(content) {
    content = content && content.toFixed(1);
    if (content == null || isNaN(content)) {
      return 'N/A';
    }
    return content + ' %';
  }

  function formatNum(value) {
    if (value == null || isNaN(value)) {
      return 'N/A';
    }

    return value.toString().replace(/\B(?<!\.\d*)(?=(\d{3})+(?!\d))/g, ',');
  }
  function onClickEditButton() {
    setOpenEditResult(true);
  }

  function pValuseIcon(pValue) {
    if (pValue == null || isNaN(pValue)) {
      return <b>N/A</b>;
    }
    if (pValue < 0.05) {
      return <b style={{ color: '#008000' }}>{pValue.toFixed(3)}</b>;
    } else {
      return <b style={{ color: '#ff0000' }}>{pValue.toFixed(3)}</b>;
    }
  }

  const useStyles = makeStyles({
    tooltip: {
      fontSize: '1em',
      maxWidth: props => (props.isTooltipWidthMaxNone ? 'none' : '600px'),
    },
  });

  const classes = useStyles();

  const HelpIconWithTooltip = ({ text, isTooltipWidthMaxNone = true }) => {
    const classes = useStyles({ isTooltipWidthMaxNone });

    return (
      <Tooltip arrow={true} placement="top" classes={{ tooltip: classes.tooltip }} title={text}>
        <Help style={{ color: '#808080', fontSize: '80%' }} />
      </Tooltip>
    );
  };

  const ThDivWithHelpIcon = ({ thText, tooltipContent, isTooltipWidthMaxNone = true }) => (
    <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
      <div style={{ alignSelf: 'center' }}>{thText}</div>
      <div style={{ alignSelf: 'flex-start', marginTop: '-4px' }}>
        <HelpIconWithTooltip text={tooltipContent} isTooltipWidthMaxNone={isTooltipWidthMaxNone} />
      </div>
    </div>
  );

  const PValueTooltipContent = () => (
    <div style={{ display: 'table' }}>
      <div style={{ display: 'table-row' }}>
        <p style={{ display: 'table-cell' }}>{`P-value`}</p>
        <p style={{ display: 'table-cell', textAlign: 'center', padding: '0 2px' }}>{` <= `}</p>
        <p style={{ display: 'table-cell' }}>{`0.05 (α)`}</p>
        <p style={{ display: 'table-cell', paddingRight: '5px' }}>{`:`}</p>
        <p style={{ display: 'table-cell' }}>{`green texts`}</p>
      </div>
      <div style={{ display: 'table-row' }}>
        <p style={{ display: 'table-cell' }}>{`P-value`}</p>
        <p style={{ display: 'table-cell', textAlign: 'center', padding: '0 2px' }}>{`>`}</p>
        <p style={{ display: 'table-cell' }}>{`0.05 (α)`}</p>
        <p style={{ display: 'table-cell', paddingRight: '5px' }}>{`:`}</p>
        <p style={{ display: 'table-cell' }}>{`red texts`}</p>
      </div>
    </div>
  );

  const CategoryThDiv = () => (
    <div style={{ display: 'flex', flexDirection: 'column' }}>
      <p style={{ lineHeight: 1 }}>Category</p>
      <p style={{ margin: 0, lineHeight: 1 }}>{`(${categoryName})`}</p>
    </div>
  );

  const TabThDiv = () => (
    <div style={{ display: 'flex', flexDirection: 'column' }}>
      <p style={{ lineHeight: 1 }}>Tab</p>
      <p style={{ margin: 0, lineHeight: 1 }}>{`(${tabName})`}</p>
    </div>
  );

  return (
    <div>
      <Fragment>
        <form id="abTestResult" autoComplete="off">
          <div className="tbl_wrap tbl_radius">
            <table className="tbl_row_typ1 tbl_channel">
              <colgroup>
                <col style={{ Width: '6%' }} />
                <col style={{ Width: '27%' }} />
                <col style={{ Width: '6%' }} />
                <col style={{ Width: '27%' }} />
                <col style={{ Width: '6%' }} />
                <col style={{ Width: 'auto' }} />
              </colgroup>
              <thead>
                <tr>
                  <th width="10%">
                    <b>Title</b>
                  </th>
                  <td width="20%">{abTestTitle}</td>
                  <th width="10%">
                    <b>Country</b>
                  </th>
                  <td width="35%">{country}</td>
                  <th width="10%">
                    <b>Category</b>
                  </th>
                  <td width="15%">{getCategoryDetails()}</td>
                </tr>
                <tr>
                  <th>
                    <b>Segment Id</b>
                  </th>
                  <td>{segmentId}</td>
                  <th>
                    <b>Segment Type</b>
                  </th>
                  <td>{segmentName}</td>
                  <th>
                    <b>Segment Description</b>
                  </th>
                  <td>{description}</td>
                  <th></th>
                  <td></td>
                </tr>
                <tr>
                  <th>
                    <b>Time (UTC)</b>
                  </th>
                  <td>
                    {abtestStartTime} ~ {abtestEndTime}
                  </td>
                  <th>
                    <b>Content Policy</b>
                  </th>
                  <td>{contentPolicy}</td>
                  <th></th>
                  <td></td>
                </tr>
                <tr>
                  <th>
                    <b>Validity</b>
                  </th>
                  <td>{validityData}</td>
                  <th>
                    <b>Reason</b>
                  </th>
                  <td class="wrap-text">{reasonData}</td>
                  <th>
                    <Button color="secondary" variant="contained" className={'btn_color2'} onClick={() => onClickEditButton()}>
                      Edit
                    </Button>
                  </th>
                  <td></td>
                </tr>
              </thead>
            </table>
          </div>
          <div className="tbl_wrap_rev  tbl_wrap tbl_radius">
            <h5 style={{ padding: '8px 0 16px 8px' }}>{`Population Size of ${defaultContent === 'Y' ? 'Group A, Group B, and Group C' : 'Group A and Group B'}`}</h5>
            {/* <table className="abTest_borderColor custom-table" border="1">
              <tr>
                <th>Group</th>
                <th>Group Code</th>
                <th>Number of UDs from {startDate} to {lastDate}</th>
                <th>Description</th>
              </tr>
              <tr>
                <td>A</td>
                <td style={{ textAlign: 'left', paddingLeft: '10px' }}>P_NO_SEGMENT_DEFAULT</td>
                <td style={{ textAlign: 'right', paddingRight: '10px' }}>{uniqueDeviceA}</td>
                <td style={{ textAlign: 'left', paddingLeft: '10px' }}>This group (belongs to no segments) watches a list of contents organized by the tab/category page</td>
              </tr>
              <tr>
                <td>B</td>
                <td style={{ textAlign: 'left', paddingLeft: '10px' }}>T_{segmentId}_RECOMMEND_B</td>
                <td style={{ textAlign: 'right', paddingRight: '10px' }}>{uniqueDeviceB}</td>
                <td style={{ textAlign: 'left', paddingLeft: '10px' }}>{getBGroupDescription()}</td>
              </tr>
              {defaultContent === 'Y' &&
                <tr>
                  <td>C</td>
                  <td style={{ textAlign: 'left', paddingLeft: '10px' }}>{groupCodeFind()}</td>
                  <td style={{ textAlign: 'right', paddingRight: '10px' }}>{uniqueDeviceC}</td>
                  <td style={{ textAlign: 'left', paddingLeft: '10px' }}>{getCGroupDescription()}</td>
                </tr>
              }
            </table> */}
            <table className="abTest_borderColor custom-table" border="1">
              <thead>
                <tr>
                  <th rowSpan="2">Day</th>
                  <th rowSpan="2">Day of the week</th>
                  <th rowSpan="2">Weekday</th>
                  <th rowSpan="2">Start (Local Time)</th>
                  <th rowSpan="2" style={{ borderRight: '1px solid' }}>
                    End (Local Time)
                  </th>
                  <th colSpan={defaultContent === 'N' ? '2' : '3'} style={{ borderRight: '1px solid' }}>
                    Number of UDs
                  </th>
                </tr>
                <tr>
                  <th style={{ borderLeft: '1px solid' }}>A</th>
                  <th style={{ borderRight: defaultContent === 'N' ? '1px solid' : '' }}>B</th>
                  {defaultContent === 'N' ? '' : <th style={{ borderRight: '1px solid' }}>C</th>}
                </tr>
              </thead>
              <tbody>
                {dailyUdsData &&
                  abTestDailyData
                    .filter(data => data.abtest_id != null)
                    .map((data, index) => (
                      <tr key={`aa_${index}`}>
                        <td>{'Day ' + (index + 1)}</td>
                        <td>{data.week_day}</td>
                        <td>{<CircleIcon style={{ width: '100%', display: 'flex', justifyContent: 'center' }} htmlColor="green" />}</td>
                        <td style={{ width: 'auto' }}>{data.start_date.slice(0, 19)}</td>
                        <td style={{ width: 'auto' }}>{data.end_date.slice(0, 19)}</td>

                        <td style={{ backgroundColor: '#ffdab9', borderLeft: '1px solid' }}>{formatNum(dailyUdsData[data.start_date.slice(0, 10)].A)}</td>
                        <td style={{ backgroundColor: '#ffb6c1', borderRight: defaultContent === 'N' ? '1px solid' : '' }}>{formatNum(dailyUdsData[data.start_date.slice(0, 10)].B)}</td>
                        {defaultContent === 'N' ? '' : <td style={{ backgroundColor: '#CFCFC4', borderRight: '1px solid' }}>{formatNum(dailyUdsData[data.start_date.slice(0, 10)].C)}</td>}
                      </tr>
                    ))}
              </tbody>
            </table>
          </div>
          <div className="tbl_wrap_rev  tbl_wrap tbl_radius">
            <h5 style={{ padding: '8px 0 16px 8px' }}>{`Engagement rate of ${defaultContent === 'Y' ? 'Group A, Group B, and Group C' : 'Group A and Group B'}`}</h5>
            <table className="abTest_borderColor custom-table" border="1">
              <tr>
                <th rowSpan="4">Day</th>
                <th rowSpan="4">Day of the week</th>
                <th rowSpan="4">Weekday</th>
                <th rowSpan="4">Start (Local Time)</th>
                <th rowSpan="4" style={{ borderRight: '1px solid' }}>
                  End (Local Time)
                </th>
                <th colSpan={defaultContent === 'N' ? '7' : '12'} style={{ borderRight: '1px solid' }}>
                  Average Duration
                </th>
                <th colSpan={defaultContent === 'N' ? '19' : '30'} style={{ borderRight: '1px solid' }}>
                  Content Clicks
                </th>
                <th colSpan={defaultContent === 'N' ? '19' : '30'} style={{ borderRight: '1px solid' }}>
                  Clicked Unique Devices
                </th>
              </tr>
              <tr>
                <th colSpan={defaultContent === 'N' ? '4' : '7'} style={{ borderRight: '1px solid' }}>
                  Daily
                </th>
                <th colSpan={defaultContent === 'N' ? '3' : '5'} style={{ borderRight: '1px solid' }}>
                  Cumulative
                </th>
                <th colSpan={defaultContent === 'N' ? '10' : '16'} style={{ borderRight: '1px solid' }}>
                  Daily
                </th>
                <th colSpan={defaultContent === 'N' ? '9' : '14'} style={{ borderRight: '1px solid' }}>
                  Cumulative
                </th>
                <th colSpan={defaultContent === 'N' ? '10' : '16'} style={{ borderRight: '1px solid' }}>
                  Daily
                </th>
                <th colSpan={defaultContent === 'N' ? '9' : '14'} style={{ borderRight: '1px solid' }}>
                  Cumulative
                </th>
              </tr>
              <tr>
                {/* <th colSpan={defaultContent === 'N' ? '2' : '3'} style={{ borderLeft: '1px solid' }}>All</th> */}
                <th colSpan={defaultContent === 'N' ? '2' : '3'}>Average(min)</th>
                <th colSpan={defaultContent === 'N' ? '2' : '4'} style={{ borderRight: '1px solid' }}>
                  Improved
                </th>
                {/* <th colSpan={defaultContent === 'N' ? '2' : '3'} style={{ borderLeft: '1px solid' }}>All</th> */}
                <th colSpan={defaultContent === 'N' ? '2' : '3'}>Average(min)</th>
                <th colSpan={defaultContent === 'N' ? '1' : '2'} style={{ borderRight: '1px solid' }}>
                  Improved
                </th>

                <th colSpan={defaultContent === 'N' ? '2' : '3'}>
                  <CategoryThDiv />
                </th>
                <th colSpan={defaultContent === 'N' ? '2' : '3'}>
                  <TabThDiv />
                </th>
                <th colSpan={defaultContent === 'N' ? '2' : '3'}>Home-App</th>
                <th colSpan={defaultContent === 'N' ? '2' : '3'}>
                  <ThDivWithHelpIcon thText={'Click Rate'} tooltipContent={'(Category / Tab) * 100'} />
                </th>
                <th colSpan={defaultContent === 'N' ? '2' : '4'} style={{ borderRight: '1px solid' }}>
                  Improved
                </th>

                <th colSpan={defaultContent === 'N' ? '2' : '3'}>
                  <CategoryThDiv />
                </th>
                <th colSpan={defaultContent === 'N' ? '2' : '3'}>
                  <TabThDiv />
                </th>
                <th colSpan={defaultContent === 'N' ? '2' : '3'}>Home-App</th>
                <th colSpan={defaultContent === 'N' ? '2' : '3'}>
                  <ThDivWithHelpIcon thText={'Click Rate'} tooltipContent={'(Category / Tab) * 100'} />
                </th>
                <th colSpan={defaultContent === 'N' ? '1' : '2'} style={{ borderRight: '1px solid' }}>
                  Improved
                </th>

                <th colSpan={defaultContent === 'N' ? '2' : '3'}>
                  <CategoryThDiv />
                </th>
                <th colSpan={defaultContent === 'N' ? '2' : '3'}>
                  <TabThDiv />
                </th>
                <th colSpan={defaultContent === 'N' ? '2' : '3'}>Home-App</th>
                <th colSpan={defaultContent === 'N' ? '2' : '3'}>
                  <ThDivWithHelpIcon thText={'UD Rate'} tooltipContent={'(Category / Home-App) * 100'} />
                </th>
                <th colSpan={defaultContent === 'N' ? '2' : '4'} style={{ borderRight: '1px solid' }}>
                  Improved
                </th>

                <th colSpan={defaultContent === 'N' ? '2' : '3'}>
                  <CategoryThDiv />
                </th>
                <th colSpan={defaultContent === 'N' ? '2' : '3'}>
                  <TabThDiv />
                </th>
                <th colSpan={defaultContent === 'N' ? '2' : '3'}>Home-App</th>
                <th colSpan={defaultContent === 'N' ? '2' : '3'}>
                  <ThDivWithHelpIcon thText={'UD Rate'} tooltipContent={'(Category / Home-App) * 100'} />
                </th>
                <th colSpan={defaultContent === 'N' ? '1' : '2'} style={{ borderRight: '1px solid' }}>
                  Improved
                </th>
              </tr>
              <tr>
                <th style={{ borderLeft: '1px solid' }}>A</th>
                <th>B</th>
                {defaultContent === 'N' ? '' : <th>C</th>}
                {/* <th>A</th>
              <th>B</th>
              {defaultContent === 'N' ? '' : <th>C</th>} */}
                <th>
                  <ThDivWithHelpIcon thText={'B over A'} tooltipContent={'(Average duration B - Average duration A) / Average duration A * 100'} />
                </th>
                <th>
                  <ThDivWithHelpIcon thText={'P-value'} tooltipContent={<PValueTooltipContent />} />
                </th>
                {defaultContent === 'N' ? (
                  ''
                ) : (
                  <th>
                    <ThDivWithHelpIcon thText={'B over C'} tooltipContent={'(Average duration B - Average duration C) / Average duration C * 100'} />
                  </th>
                )}
                {defaultContent === 'N' ? (
                  ''
                ) : (
                  <th>
                    <ThDivWithHelpIcon thText={'P-value'} tooltipContent={<PValueTooltipContent />} />
                  </th>
                )}

                <th style={{ borderLeft: '1px solid' }}>A</th>
                <th>B</th>
                {defaultContent === 'N' ? '' : <th>C</th>}
                {/* <th>A</th>
              <th>B</th>
              {defaultContent === 'N' ? '' : <th>C</th>} */}
                <th>
                  <ThDivWithHelpIcon thText={'B over A'} tooltipContent={'(Average duration B - Average duration A) / Average duration A * 100'} />
                </th>
                {defaultContent === 'N' ? (
                  ''
                ) : (
                  <th>
                    <ThDivWithHelpIcon thText={'B over C'} tooltipContent={'(Average duration B - Average duration C) / Average duration C * 100'} />
                  </th>
                )}

                <th style={{ borderLeft: '1px solid' }}>A</th>
                <th>B</th>
                {defaultContent === 'N' ? '' : <th>C</th>}
                <th>A</th>
                <th>B</th>
                {defaultContent === 'N' ? '' : <th>C</th>}
                <th>A</th>
                <th>B</th>
                {defaultContent === 'N' ? '' : <th>C</th>}
                <th>
                  <ThDivWithHelpIcon thText={'A'} tooltipContent={'(Category A / Tab A) * 100'} />
                </th>
                <th>
                  <ThDivWithHelpIcon thText={'B'} tooltipContent={'(Category B / Tab B) * 100'} />
                </th>
                {defaultContent === 'N' ? (
                  ''
                ) : (
                  <th>
                    <ThDivWithHelpIcon thText={'C'} tooltipContent={'(Category C / Tab C) * 100'} />
                  </th>
                )}
                <th>
                  <ThDivWithHelpIcon thText={'B over A'} tooltipContent={'(Click rate B - Click rate A) / Click rate A * 100'} />
                </th>
                <th>
                  <ThDivWithHelpIcon thText={'P-value'} tooltipContent={<PValueTooltipContent />} />
                </th>
                {defaultContent === 'N' ? (
                  ''
                ) : (
                  <th>
                    <ThDivWithHelpIcon thText={'B over C'} tooltipContent={'(Click rate B - Click rate C) / Click rate C * 100'} />
                  </th>
                )}
                {defaultContent === 'N' ? (
                  ''
                ) : (
                  <th>
                    <ThDivWithHelpIcon thText={'P-value'} tooltipContent={<PValueTooltipContent />} />
                  </th>
                )}

                <th style={{ borderLeft: '1px solid' }}>A</th>
                <th>B</th>
                {defaultContent === 'N' ? '' : <th>C</th>}
                <th>A</th>
                <th>B</th>
                {defaultContent === 'N' ? '' : <th>C</th>}
                <th>A</th>
                <th>B</th>
                {defaultContent === 'N' ? '' : <th>C</th>}
                <th>
                  <ThDivWithHelpIcon thText={'A'} tooltipContent={'(Category A / Tab A) * 100'} />
                </th>
                <th>
                  <ThDivWithHelpIcon thText={'B'} tooltipContent={'(Category B / Tab B) * 100'} />
                </th>
                {defaultContent === 'N' ? (
                  ''
                ) : (
                  <th>
                    <ThDivWithHelpIcon thText={'C'} tooltipContent={'(Category C / Tab C) * 100'} />
                  </th>
                )}
                <th>
                  <ThDivWithHelpIcon thText={'B over A'} tooltipContent={'(Click rate B - Click rate A) / Click rate A * 100'} />
                </th>
                {defaultContent === 'N' ? (
                  ''
                ) : (
                  <th>
                    <ThDivWithHelpIcon thText={'B over C'} tooltipContent={'(Click rate B - Click rate C) / Click rate C * 100'} />
                  </th>
                )}

                <th style={{ borderLeft: '1px solid' }}>A</th>
                <th>B</th>
                {defaultContent === 'N' ? '' : <th>C</th>}
                <th>A</th>
                <th>B</th>
                {defaultContent === 'N' ? '' : <th>C</th>}
                <th>A</th>
                <th>B</th>
                {defaultContent === 'N' ? '' : <th>C</th>}
                <th>
                  <ThDivWithHelpIcon thText={'A'} tooltipContent={'(Category A / Home-App A) * 100'} />
                </th>
                <th>
                  <ThDivWithHelpIcon thText={'B'} tooltipContent={'(Category B / Home-App B) * 100'} />
                </th>
                {defaultContent === 'N' ? (
                  ''
                ) : (
                  <th>
                    <ThDivWithHelpIcon thText={'C'} tooltipContent={'(Category C / Home-App C) * 100'} />
                  </th>
                )}
                <th>
                  <ThDivWithHelpIcon thText={'B over A'} tooltipContent={'(UD rate B - UD rate A) / UD rate A * 100'} />
                </th>
                <th>
                  <ThDivWithHelpIcon thText={'P-value'} tooltipContent={<PValueTooltipContent />} />
                </th>
                {defaultContent === 'N' ? (
                  ''
                ) : (
                  <th>
                    <ThDivWithHelpIcon thText={'B over C'} tooltipContent={'(UD rate B - UD rate C) / UD rate C * 100'} />
                  </th>
                )}
                {defaultContent === 'N' ? (
                  ''
                ) : (
                  <th>
                    <ThDivWithHelpIcon thText={'P-value'} tooltipContent={<PValueTooltipContent />} />
                  </th>
                )}

                <th style={{ borderLeft: '1px solid' }}>A</th>
                <th>B</th>
                {defaultContent === 'N' ? '' : <th>C</th>}
                <th>A</th>
                <th>B</th>
                {defaultContent === 'N' ? '' : <th>C</th>}
                <th>A</th>
                <th>B</th>
                {defaultContent === 'N' ? '' : <th>C</th>}
                <th>
                  <ThDivWithHelpIcon thText={'A'} tooltipContent={'(Category A / Home-App A) * 100'} />
                </th>
                <th>
                  <ThDivWithHelpIcon thText={'B'} tooltipContent={'(Category B / Home-App B) * 100'} />
                </th>
                {defaultContent === 'N' ? (
                  ''
                ) : (
                  <th>
                    <ThDivWithHelpIcon thText={'C'} tooltipContent={'(Category C / Home-App C) * 100'} />
                  </th>
                )}
                {defaultContent === 'N' ? (
                  <th style={{ borderRight: '1px solid' }}>
                    <ThDivWithHelpIcon thText={'B over A'} tooltipContent={'(UD rate B - UD rate A) / UD rate A * 100'} />
                  </th>
                ) : (
                  <th>
                    <ThDivWithHelpIcon thText={'B over A'} tooltipContent={'(UD rate B - UD rate A) / UD rate A * 100'} />
                  </th>
                )}
                {defaultContent === 'N' ? (
                  ''
                ) : (
                  <th style={{ borderRight: '1px solid' }}>
                    <ThDivWithHelpIcon thText={'B over C'} tooltipContent={'(UD rate B - UD rate C) / UD rate C * 100'} />
                  </th>
                )}
              </tr>
              {abTestDailyData
                .filter(data => data.abtest_id != null)
                .map((data, index) => (
                  <tr key={index}>
                    <td>{'Day ' + (index + 1)}</td>
                    <td>{data.week_day}</td>
                    <td>{<CircleIcon style={{ width: '100%', display: 'flex', justifyContent: 'center' }} htmlColor="green" />}</td>
                    <td style={{ width: 'auto' }}>{data.start_date.slice(0, 19)}</td>
                    <td style={{ width: 'auto' }}>{data.end_date.slice(0, 19)}</td>

                    {/* <td style={{ backgroundColor: '#ffdab9', borderLeft: '1px solid' }}>{data.all_content_duration_A.toString().replace(/\B(?<!\.\d*)(?=(\d{3})+(?!\d))/g, ",")}</td>
                <td style={{ backgroundColor: '#ffb6c1' }}>{data.all_content_duration_B.toString().replace(/\B(?<!\.\d*)(?=(\d{3})+(?!\d))/g, ",")}</td>
                {defaultContent === 'N' ? '' : <td style={{ backgroundColor: '#CFCFC4' }}>{data.all_content_duration_C.toString().replace(/\B(?<!\.\d*)(?=(\d{3})+(?!\d))/g, ",")}</td>} */}
                    <td style={{ backgroundColor: '#ffdab9', borderLeft: '1px solid' }}>{formatNum(data.all_content_A)}</td>
                    <td style={{ backgroundColor: '#ffb6c1' }}>{formatNum(data.all_content_B)}</td>
                    {defaultContent === 'N' ? '' : <td style={{ backgroundColor: '#CFCFC4' }}>{formatNum(data.all_content_C)}</td>}
                    <td style={{ backgroundColor: '#e6e6fa', color: calImprovedRate(data.all_content_B, data.all_content_A).includes('-') ? 'red' : 'black' }}>
                      <b>{calImprovedRate(data.all_content_B, data.all_content_A)}</b>
                    </td>
                    <td style={{ backgroundColor: '#e6e6fa' }}>{pValuseIcon(data.pvalue_duration_A_B)}</td>
                    {defaultContent === 'N' ? (
                      ''
                    ) : (
                      <td style={{ backgroundColor: '#e6e6fa', color: calImprovedRate(data.all_content_B, data.all_content_C).includes('-') ? 'red' : 'black' }}>
                        <b>{calImprovedRate(data.all_content_B, data.all_content_C)}</b>
                      </td>
                    )}
                    {defaultContent === 'N' ? '' : <td style={{ backgroundColor: '#e6e6fa' }}>{pValuseIcon(data.pvalue_duration_B_C)}</td>}

                    {/* <td style={{ backgroundColor: '#ffdab9', borderLeft: '1px solid' }}>{data.agg_all_content_duration_A.toString().replace(/\B(?<!\.\d*)(?=(\d{3})+(?!\d))/g, ",")}</td>
                <td style={{ backgroundColor: '#ffb6c1' }}>{data.agg_all_content_duration_B.toString().replace(/\B(?<!\.\d*)(?=(\d{3})+(?!\d))/g, ",")}</td>
                {defaultContent === 'N' ? '' : <td style={{ backgroundColor: '#CFCFC4' }}>{data.agg_all_content_duration_C.toString().replace(/\B(?<!\.\d*)(?=(\d{3})+(?!\d))/g, ",")}</td>} */}
                    <td style={{ backgroundColor: '#ffdab9', borderLeft: '1px solid' }}>{formatNum(data.agg_all_content_A)}</td>
                    <td style={{ backgroundColor: '#ffb6c1' }}>{formatNum(data.agg_all_content_B)}</td>
                    {defaultContent === 'N' ? '' : <td style={{ backgroundColor: '#CFCFC4' }}>{formatNum(data.agg_all_content_C)}</td>}
                    <td style={{ backgroundColor: '#e6e6fa', color: calImprovedRate(data.agg_all_content_B, data.agg_all_content_A).includes('-') ? 'red' : 'black' }}>
                      <b>{calImprovedRate(data.agg_all_content_B, data.agg_all_content_A)}</b>
                    </td>
                    {defaultContent === 'N' ? (
                      ''
                    ) : (
                      <td style={{ backgroundColor: '#e6e6fa', color: calImprovedRate(data.agg_all_content_B, data.agg_all_content_C).includes('-') ? 'red' : 'black' }}>
                        <b>{calImprovedRate(data.agg_all_content_B, data.agg_all_content_C)}</b>
                      </td>
                    )}

                    <td style={{ backgroundColor: '#ffdab9', borderLeft: '1px solid' }}>{formatNum(data.abtest_content_click_A)}</td>
                    <td style={{ backgroundColor: '#ffb6c1' }}>{formatNum(data.abtest_content_click_B)}</td>
                    {defaultContent === 'N' ? '' : <td style={{ backgroundColor: '#CFCFC4' }}>{formatNum(data.abtest_content_click_C)}</td>}
                    <td style={{ backgroundColor: '#ffdab9' }}>{formatNum(data.tab_content_click_A)}</td>
                    <td style={{ backgroundColor: '#ffb6c1' }}>{formatNum(data.tab_content_click_B)}</td>
                    {defaultContent === 'N' ? '' : <td style={{ backgroundColor: '#CFCFC4' }}>{formatNum(data.tab_content_click_C)}</td>}
                    <td style={{ backgroundColor: '#ffdab9' }}>{formatNum(data.all_content_click_A)}</td>
                    <td style={{ backgroundColor: '#ffb6c1' }}>{formatNum(data.all_content_click_B)}</td>
                    {defaultContent === 'N' ? '' : <td style={{ backgroundColor: '#CFCFC4' }}>{formatNum(data.all_content_click_C)}</td>}
                    <td style={{ backgroundColor: '#ffdab9' }}>{contentClickRate(data.content_A)}</td>
                    <td style={{ backgroundColor: '#ffb6c1' }}>{contentClickRate(data.content_B)}</td>
                    {defaultContent === 'N' ? '' : <td style={{ backgroundColor: '#CFCFC4' }}>{contentClickRate(data.content_C)}</td>}
                    <td style={{ backgroundColor: '#e6e6fa', color: calImprovedRate(data.content_B, data.content_A).includes('-') ? 'red' : 'black' }}>
                      <b>{calImprovedRate(data.content_B, data.content_A)}</b>
                    </td>
                    <td style={{ backgroundColor: '#e6e6fa' }}>{pValuseIcon(data.pvalue_content_click_A_B)}</td>
                    {defaultContent === 'N' ? (
                      ''
                    ) : (
                      <td style={{ backgroundColor: '#e6e6fa', color: calImprovedRate(data.content_B, data.content_C).includes('-') ? 'red' : 'black' }}>
                        <b>{calImprovedRate(data.content_B, data.content_C)}</b>
                      </td>
                    )}
                    {defaultContent === 'N' ? '' : <td style={{ backgroundColor: '#e6e6fa' }}>{pValuseIcon(data.pvalue_content_click_B_C)}</td>}

                    <td style={{ backgroundColor: '#ffdab9', borderLeft: '1px solid' }}>{formatNum(data.agg_abtest_content_click_A)}</td>
                    <td style={{ backgroundColor: '#ffb6c1' }}>{formatNum(data.agg_abtest_content_click_B)}</td>
                    {defaultContent === 'N' ? '' : <td style={{ backgroundColor: '#CFCFC4' }}>{formatNum(data.agg_abtest_content_click_C)}</td>}
                    <td style={{ backgroundColor: '#ffdab9' }}>{formatNum(data.agg_tab_content_click_A)}</td>
                    <td style={{ backgroundColor: '#ffb6c1' }}>{formatNum(data.agg_tab_content_click_B)}</td>
                    {defaultContent === 'N' ? '' : <td style={{ backgroundColor: '#CFCFC4' }}>{formatNum(data.agg_tab_content_click_C)}</td>}
                    <td style={{ backgroundColor: '#ffdab9' }}>{formatNum(data.agg_all_content_click_A)}</td>
                    <td style={{ backgroundColor: '#ffb6c1' }}>{formatNum(data.agg_all_content_click_B)}</td>
                    {defaultContent === 'N' ? '' : <td style={{ backgroundColor: '#CFCFC4' }}>{formatNum(data.agg_all_content_click_C)}</td>}
                    <td style={{ backgroundColor: '#ffdab9' }}>{contentClickRate(data.agg_content_A)}</td>
                    <td style={{ backgroundColor: '#ffb6c1' }}>{contentClickRate(data.agg_content_B)}</td>
                    {defaultContent === 'N' ? '' : <td style={{ backgroundColor: '#CFCFC4' }}>{contentClickRate(data.agg_content_C)}</td>}
                    <td style={{ backgroundColor: '#e6e6fa', color: calImprovedRate(data.agg_content_B, data.agg_content_A).includes('-') ? 'red' : 'black' }}>
                      <b>{calImprovedRate(data.agg_content_B, data.agg_content_A)}</b>
                    </td>
                    {defaultContent === 'N' ? (
                      ''
                    ) : (
                      <td style={{ backgroundColor: '#e6e6fa', color: calImprovedRate(data.agg_content_B, data.agg_content_C).includes('-') ? 'red' : 'black' }}>
                        <b>{calImprovedRate(data.agg_content_B, data.agg_content_C)}</b>
                      </td>
                    )}

                    <td style={{ backgroundColor: '#ffdab9', borderLeft: '1px solid' }}>{formatNum(data.abtest_content_click_unique_device_A)}</td>
                    <td style={{ backgroundColor: '#ffb6c1' }}>{formatNum(data.abtest_content_click_unique_device_B)}</td>
                    {defaultContent === 'N' ? '' : <td style={{ backgroundColor: '#CFCFC4' }}>{formatNum(data.abtest_content_click_unique_device_C)}</td>}
                    <td style={{ backgroundColor: '#ffdab9' }}>{formatNum(data.tab_content_click_unique_device_A)}</td>
                    <td style={{ backgroundColor: '#ffb6c1' }}>{formatNum(data.tab_content_click_unique_device_B)}</td>
                    {defaultContent === 'N' ? '' : <td style={{ backgroundColor: '#CFCFC4' }}>{formatNum(data.tab_content_click_unique_device_C)}</td>}
                    <td style={{ backgroundColor: '#ffdab9' }}>{formatNum(data.homeapp_unique_device_A)}</td>
                    <td style={{ backgroundColor: '#ffb6c1' }}>{formatNum(data.homeapp_unique_device_B)}</td>
                    {defaultContent === 'N' ? '' : <td style={{ backgroundColor: '#CFCFC4' }}>{formatNum(data.homeapp_unique_device_C)}</td>}
                    <td style={{ backgroundColor: '#ffdab9' }}>{contentClickRate(data.uniquedevice_A)}</td>
                    <td style={{ backgroundColor: '#ffb6c1' }}>{contentClickRate(data.uniquedevice_B)}</td>
                    {defaultContent === 'N' ? '' : <td style={{ backgroundColor: '#CFCFC4' }}>{contentClickRate(data.uniquedevice_C)}</td>}
                    <td style={{ backgroundColor: '#e6e6fa', color: calImprovedRate(data.uniquedevice_B, data.uniquedevice_A).includes('-') ? 'red' : 'black' }}>
                      <b>{calImprovedRate(data.uniquedevice_B, data.uniquedevice_A)}</b>
                    </td>
                    <td style={{ backgroundColor: '#e6e6fa' }}>{pValuseIcon(data.pvalue_unique_device_A_B)}</td>
                    {defaultContent === 'N' ? (
                      ''
                    ) : (
                      <td style={{ backgroundColor: '#e6e6fa', color: calImprovedRate(data.uniquedevice_B, data.uniquedevice_C).includes('-') ? 'red' : 'black' }}>
                        <b>{calImprovedRate(data.uniquedevice_B, data.uniquedevice_C)}</b>
                      </td>
                    )}
                    {defaultContent === 'N' ? '' : <td style={{ backgroundColor: '#e6e6fa' }}>{pValuseIcon(data.pvalue_unique_device_B_C)}</td>}

                    <td style={{ backgroundColor: '#ffdab9', borderLeft: '1px solid' }}>{formatNum(data.agg_abtest_content_click_unique_device_A)}</td>
                    <td style={{ backgroundColor: '#ffb6c1' }}>{formatNum(data.agg_abtest_content_click_unique_device_B)}</td>
                    {defaultContent === 'N' ? '' : <td style={{ backgroundColor: '#CFCFC4' }}>{formatNum(data.agg_abtest_content_click_unique_device_C)}</td>}
                    <td style={{ backgroundColor: '#ffdab9' }}>{formatNum(data.agg_tab_unique_device_A)}</td>
                    <td style={{ backgroundColor: '#ffb6c1' }}>{formatNum(data.agg_tab_unique_device_B)}</td>
                    {defaultContent === 'N' ? '' : <td style={{ backgroundColor: '#CFCFC4' }}>{formatNum(data.agg_tab_unique_device_C)}</td>}
                    <td style={{ backgroundColor: '#ffdab9' }}>{formatNum(data.agg_homeapp_unique_device_A)}</td>
                    <td style={{ backgroundColor: '#ffb6c1' }}>{formatNum(data.agg_homeapp_unique_device_B)}</td>
                    {defaultContent === 'N' ? '' : <td style={{ backgroundColor: '#CFCFC4' }}>{formatNum(data.agg_homeapp_unique_device_C)}</td>}
                    <td style={{ backgroundColor: '#ffdab9' }}>{contentClickRate(data.agg_uniquedevice_A)}</td>
                    <td style={{ backgroundColor: '#ffb6c1' }}>{contentClickRate(data.agg_uniquedevice_B)}</td>
                    {defaultContent === 'N' ? '' : <td style={{ backgroundColor: '#CFCFC4' }}>{contentClickRate(data.agg_uniquedevice_C)}</td>}
                    <td style={{ backgroundColor: '#e6e6fa', color: calImprovedRate(data.agg_uniquedevice_B, data.agg_uniquedevice_A).includes('-') ? 'red' : 'black' }}>
                      <b>{calImprovedRate(data.agg_uniquedevice_B, data.agg_uniquedevice_A)}</b>
                    </td>
                    {defaultContent === 'N' ? (
                      ''
                    ) : (
                      <td style={{ backgroundColor: '#e6e6fa', color: calImprovedRate(data.agg_uniquedevice_B, data.agg_uniquedevice_C).includes('-') ? 'red' : 'black' }}>
                        <b>{calImprovedRate(data.agg_uniquedevice_B, data.agg_uniquedevice_C)}</b>
                      </td>
                    )}
                  </tr>
                ))}
            </table>
          </div>
          <span></span>
          <div className="tbl_wrap tbl_radius">
            <div className="channel_mapping_wrap">
              {abTestDailyData.length > 0 && (
                <div style={{ width: '30%', marginTop: '2%' }}>
                  <ABContentDuration defaultContent={defaultContent} />
                </div>
              )}
              {abTestDailyData.length > 0 && (
                <div style={{ width: '30%', marginTop: '2%', marginLeft: '3%' }}>
                  <ABContentClick defaultContent={defaultContent} />
                </div>
              )}
              {abTestDailyData.length > 0 && (
                <div style={{ width: '30%', marginTop: '2%', marginLeft: '3%' }}>
                  <ABUniqueDevice defaultContent={defaultContent} />
                </div>
              )}
            </div>
            {abTestDailyData.length > 0 && (
              <div style={{ display: 'flex', font: 'italic bold 20px Helvetica Neue', color: '#666', paddingBottom: '2%' }}>
                <div style={{ width: '30%' }}>
                  <div style={{ display: 'flex', flexDirection: 'column', paddingLeft: 8, alignItems: 'center' }}>
                    <p>{`Cumulative B over A: ${durationCumulativeBoverA}`}</p>
                    {defaultContent !== 'N' && <p style={{ marginTop: 4 }}>{`Cumulative B over C: ${durationCumulativeBoverC}`}</p>}
                  </div>
                </div>
                <div style={{ width: '30%', marginLeft: '3%' }}>
                  <div style={{ display: 'flex', flexDirection: 'column', paddingLeft: 8, alignItems: 'center' }}>
                    <p>{`Cumulative B over A: ${clickCumulativeBoverA}`}</p>
                    {defaultContent !== 'N' && <p style={{ marginTop: 4 }}>{`Cumulative B over C: ${clickCumulativeBoverC}`}</p>}
                  </div>
                </div>
                <div style={{ width: '30%', marginLeft: '3%' }}>
                  <div style={{ display: 'flex', flexDirection: 'column', paddingLeft: 8, alignItems: 'center' }}>
                    <p>{`Cumulative B over A: ${udCumulativeBoverA}`}</p>
                    {defaultContent !== 'N' && <p style={{ marginTop: 4 }}>{`Cumulative B over C: ${udCumulativeBoverC}`}</p>}
                  </div>
                </div>
              </div>
            )}
          </div>
          {/*
        <div className="tbl_wrap tbl_radius">
          {abTestDailyData.length > 0 &&
            <div style={{ marginTop: '2%' }}>
              <ABContentClick />
            </div>
          }
        </div>
        <div className="tbl_wrap tbl_radius">
          {abTestDailyData.length > 0 &&
            <div style={{ marginTop: '2%' }}>
              <ABUniqueDevice />
            </div>
          }
        </div>
        */}
          <div className="btn_set_wrap">
            <Button
              className="btn_color2"
              onClick={() => {
                history.push('/RECOMMEND/antest');
              }}>
              Back
            </Button>
          </div>
        </form>
      </Fragment>
      <Dialog className={'pop_wrap'} open={openEditResult}>
        <EditReasonValidityDialog handleTabClose={() => setOpenEditResult(false)} idx={segMentId} validity={validity} reason={reason} setReason={setReason} setValidity={setValidity} setReasonData={setReasonData} setValidityData={setValidityData} />
      </Dialog>
    </div>
  );
}

ABTestResult.propTypes = {
  id: PropTypes.object.isRequired,
  getAbTestDailyData: PropTypes.func.isRequired,
  abTestDailyData: PropTypes.object.isRequired,
  abTestTabCategory: PropTypes.array.isRequired,
};

const mapStateToProps = state => ({
  abTestDailyData: state.homeApp.abTestDailyData,
  abTestTabCategory: state.homeApp.abTestTabCategory,
});

const mapDispatchToProps = dispatch => ({
  getAbTestDailyData(abTestId, abtestSubid) {
    dispatch(getAbTestDailyData(abTestId, abtestSubid));
  },
});

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(ABTestResult));
